import * as LucideIcons from 'lucide-react';

// Custom SVG icons for services not in lucide-react
const customIcons = {
  // Add custom icons here if needed
};

// Combine custom icons with lucide-react icons
const allIcons = { ...customIcons, ...LucideIcons };

export const digitalProfileConfig = {
  name: "Matt Guzman",
  initials: "MG",
  subtitle: "Yoga Instructor & Wellness Advocate",
  profileImage: "yoga-matt-icon.jpg",
  backgroundColor: "bg-green-900",
  textColor: "text-neutral-50",
  headerGradient: {
    from: "from-green-400",
    to: "to-emerald-300",
  },
  siteBackground: {
    type: 'gradient',
    color: 'bg-stone-800',
    gradient: {
      from: 'from-stone-800',
      to: 'to-stone-700',
      direction: 'bg-gradient-to-b'
    }
  },
  socialLinks: [
    { icon: 'Mail', url: "mailto:yogamatt2024@gmail.com", label: "Email" },
    { icon: 'Instagram', url: "https://www.instagram.com/yogamatt2024/", label: "Instagram" },
    { icon: 'Youtube', url: "https://www.youtube.com/@yogamatt2024/", label: "YouTube" },
    { icon: 'Facebook', url: "https://www.facebook.com/profile.php?id=61568919309072/", label: "Facebook" },
    { icon: 'Linkedin', url: "https://www.linkedin.com/in/matt-guzman-3b9b42339/", label: "LinkedIn" },
    // Add or remove social links as needed
  ],
};

export { allIcons };