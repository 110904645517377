import React from 'react';
import DigitalProfileCard from './components/DigitalProfileCard';

function App() {
  return (
    <div className="App">
      <DigitalProfileCard />
    </div>
  );
}

export default App;